<template>
  <div
    :id="`wrapper_${index}`"
    class="max-h-screen overflow-hidden"
  >
    <div
      :class="slice.variation === 'default' ? 'mx-auto' : ''"
      class="grid grid-cols-1 md:grid-cols-2"
    >
      <!-- Content -->
      <div
        class="relative md:min-h-[60vh] xl:h-screen"
        :class="useTheme(slice.primary.theme ?? 'default')"
      >
        <div class="copy left-0 top-0 h-full p-10 md:flex md:items-center xl:max-w-[50vw]">
          <h2 class="title1 text-hero-header text-white md:absolute md:left-10 md:top-20 md:text-desktop-hero-header">
            {{ slice.primary.title_first }}
          </h2>
          <h2 class="title2 text-hero-header text-white md:absolute md:bottom-20 md:left-10 md:text-desktop-hero-header">
            {{ slice.primary.title_second }}
          </h2>

          <div class="text">
            <PrismicRichText
              :field="slice.primary.content"
              class="my-10 text-body-copy xl:text-desktop-large-copy"
            />

            <UIButton
              v-for="(button, index) in slice.primary.actions"
              :key="index"
              :type="button.type"
              :label="button.label"
              :link="prismic.asLink(button.link)"
            />
          </div>
        </div>
      </div>

      <!-- Image -->
      <div
        v-if="slice.primary.image"
        class="max-w-none md:w-[50vw]"
        :class="slice.variation === 'right' ? 'md:order-first' : ''"
      >
        <PrismicImage
          :field="slice.primary.image"
          class="object-cover md:min-h-[60vh] xl:h-screen"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Content } from "@prismicio/client";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const prismic = usePrismic();
// The array passed to `getSliceComponentProps` is purely optional.
// Consider it as a visual hint for you when templating your slice.
const props = defineProps(
  getSliceComponentProps<Content.TextImageSlice>([
    "slice",
    "index",
    "slices",
    "context",
  ]),
);

onMounted(() => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: `#wrapper_${props.index.toString()}`,
      start: "top 75%",
      end: "bottom bottom",
      scrub: true,
    },
  });

  tl.from(`#wrapper_${props.index.toString()} .copy`, { y: "100px", opacity: 0, duration: 0.5 });
  tl.fromTo(`#wrapper_${props.index.toString()} .title2`, { top: "220px" }, { top: "auto", duration: 1, delay: 2 });
  tl.from(`#wrapper_${props.index.toString()} .text`, { opacity: 0, duration: 1 }, "-=.5");
});
</script>
